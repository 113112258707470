import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { DateFormatterPipe } from './pipes/date-formatter.pipe';
import { OrganizationUnitSelectorComponent } from './components/organization-unit-selector/organization-unit-selector.component';
import { FileDownloadHelperService } from './services/file-download-helper.service';
import { NavItemsComponent } from './components/nav-items/nav-items.component';
import { VersionInfoComponent } from './components/version-info/version-info.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [OrganizationUnitSelectorComponent, DateFormatterPipe, NavItemsComponent, VersionInfoComponent],
  imports: [
    CoreModule,
    CommonModule,
    ThemeSharedModule,
    ThemeLeptonModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    NgSelectModule,
  ],
  exports: [
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    OrganizationUnitSelectorComponent,
    DateFormatterPipe,
  ],
  providers: [FileDownloadHelperService],
})
export class SharedModule {}
