import { VALIDATION_BLUEPRINTS } from '@ngx-validate/core';
import { LocalizationService, ConfigStateService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ValidationMessageService {
  whiteSpaceNotAllowed: string;

  constructor(
    private configStateService: ConfigStateService,
    public localizationService: LocalizationService
  ) {
    let currentLanguage$: Observable<any>;
    currentLanguage$ = configStateService.getDeep$('localization.currentCulture');
    currentLanguage$.subscribe(language => {
      this.whiteSpaceNotAllowed = this.localizationService.instant(
        '::Validation:WhitespaceNotAllowed'
      );
    });
  }
}

export const VALIDATION_MESSAGE_PROVIDER = [
  {
    provide: VALIDATION_BLUEPRINTS,
    useClass: ValidationMessageService,
  },
];
