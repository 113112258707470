<abp-page [title]="'AbpIdentity::Users' | abpLocalization" [toolbar]="data">
  <div id="identity-users-wrapper">
    <abp-advanced-entity-filters [list]="list" localizationSourceName="AbpIdentity">
      <abp-advanced-entity-filters-form>
        <form #filterForm (keyup.enter)="list.get()">
          <div class="row">
            <div class="col-12 col-sm-3">
              <div class="mb-3">
                <label for="roleIdFilter" class="form-label">
                  {{ 'AbpIdentity::Role' | abpLocalization }}
                </label>
                <abp-lookup-select
                  cid="roleIdFilter"
                  lookupNameProp="name"
                  [getFn]="roleService.getList"
                  [(ngModel)]="filters.roleId"
                  [ngModelOptions]="{ standalone: true }"
                ></abp-lookup-select>
              </div>
            </div>

            <!-- <div class="col-12 col-sm-3">
              <div class="mb-3">
                <label for="organizationUnitIdFilter" class="form-label">
                  {{ 'AbpIdentity::OrganizationUnit' | abpLocalization }}
                </label>
                <abp-lookup-select
                  cid="organizationUnitIdFilter"
                  [getFn]="organizationUnitService.getList"
                  [(ngModel)]="filters.organizationUnitId"
                  [ngModelOptions]="{ standalone: true }"
                ></abp-lookup-select>
              </div>
            </div> -->

            <div class="col-12 col-sm-3">
              <div class="form-group mb-3">
                <label for="UserName" class="form-label">
                  {{ 'AbpIdentity::UserName' | abpLocalization }}
                </label>
                <input
                  type="text"
                  id="UserName"
                  class="form-control"
                  [(ngModel)]="filters.userName"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>

            <div class="col-12 col-sm-3">
              <div class="form-group mb-3">
                <label for="PhoneNumber" class="form-label">
                  {{ 'AbpIdentity::PhoneNumber' | abpLocalization }}
                </label>
                <input
                  type="text"
                  id="PhoneNumber"
                  class="form-control"
                  [(ngModel)]="filters.phoneNumber"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>

            <div class="col-12 col-sm-3">
              <div class="form-group mb-3">
                <label for="EmailAddress" class="form-label">
                  {{ 'AbpIdentity::EmailAddress' | abpLocalization }}
                </label>
                <input
                  type="text"
                  id="EmailAddress"
                  class="form-control"
                  [(ngModel)]="filters.emailAddress"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>

            <div class="d-flex col-3">
              <div class="align-self-center">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="is-locked-out"
                    [(ngModel)]="filters.isLockedOut"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <label class="form-check-label" for="is-locked-out">{{
                    'AbpIdentity::Lock' | abpLocalization
                  }}</label>
                </div>
              </div>
              <div class="align-self-center ms-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="not-active"
                    [(ngModel)]="filters.notActive"
                    [ngModelOptions]="{ standalone: true }"
                  />
                  <label class="form-check-label" for="not-active">{{
                    'AbpIdentity::NotActive' | abpLocalization
                  }}</label>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-auto align-self-end mb-3">
              <div class="row">
                <div class="col-6 col-sm-auto d-grid">
                  <button type="button" class="btn btn-outline-primary" (click)="clearFilters()">
                    <span>{{ 'AbpUi::Clear' | abpLocalization }}</span>
                  </button>
                </div>
                <div class="col-6 col-sm-auto d-grid">
                  <button type="button" class="btn btn-primary" (click)="list.get()">
                    <span>{{ 'AbpUi::Refresh' | abpLocalization }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </abp-advanced-entity-filters-form>
    </abp-advanced-entity-filters>
    <div class="card">
      <abp-extensible-table
        [data]="data.items"
        [recordsTotal]="data.totalCount"
        [list]="list"
      ></abp-extensible-table>
    </div>
  </div>
</abp-page>

<abp-modal [(visible)]="isModalVisible" [busy]="modalBusy" (disappear)="form = null">
  <ng-template #abpHeader>
    <h3>{{ (selected?.id ? 'AbpIdentity::Edit' : 'AbpIdentity::NewUser') | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form *ngIf="form" [formGroup]="form" id="userForm" (ngSubmit)="save()" validateOnSubmit>
      <ul id="user-nav-tabs" ngbNav #nav="ngbNav" class="nav-tabs">
        <li id="user-informations" ngbNavItem>
          <a ngbNavLink>{{ 'AbpIdentity::UserInformations' | abpLocalization }}</a>
          <ng-template ngbNavContent
            ><abp-extensible-form [selectedRecord]="selected"></abp-extensible-form>

            <div class="form-group">
              <label for="userUnit" class="required-field-asteriks">
                {{ '::OrganizationUnit' | abpLocalization }}
              </label>
              <app-organization-unit-selector
                id="userUnit"
                formControlName="userUnit"
              ></app-organization-unit-selector>
            </div>
          </ng-template>
        </li>
        <li id="user-roles" ngbNavItem>
          <a ngbNavLink>{{ 'AbpIdentity::Roles' | abpLocalization }}</a>
          <ng-template ngbNavContent>
            <div
              *ngFor="let roleGroup of roleGroups; let i = index; trackBy: trackByFn"
              class="form-check mb-3"
            >
              <input
                type="checkbox"
                class="form-check-input"
                [attr.id]="'roles-' + i"
                [formControl]="roleGroup.controls[roles[i].name]"
              />
              <label class="form-check-label" [attr.for]="'roles-' + i">{{ roles[i].name }}</label>
            </div></ng-template
          >
        </li>
        <!-- <li id="user-organization-units" ngbNavItem>
          <a ngbNavLink>{{ 'AbpIdentity::OrganizationUnits' | abpLocalization }}</a>
          <ng-template ngbNavContent>
            <abp-tree
              *ngIf="organization.nodes?.length; else noDataMessage"
              [checkStrictly]="true"
              [checkable]="true"
              [nodes]="organization.nodes"
              [isNodeSelected]="organization.selectFn"
              [(checkedKeys)]="organization.checkedKeys"
              [(expandedKeys)]="organization.expandedKeys"
            ></abp-tree>

            <ng-template #noDataMessage>
              <p class="text-muted">
                {{ 'AbpIdentity::NoOrganizationUnits' | abpLocalization }}
              </p>
            </ng-template>
          </ng-template>
        </li> -->
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2 fade-in-top"></div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpIdentity::Cancel' | abpLocalization }}
    </button>
    <abp-button iconClass="fa fa-check" buttonType="submit" formName="userForm">{{
      'AbpIdentity::Save' | abpLocalization
    }}</abp-button>
  </ng-template>
</abp-modal>

<abp-permission-management
  *abpReplaceableTemplate="{
    inputs: {
      providerName: { value: 'U' },
      providerKey: { value: providerKey },
      hideBadges: { value: true },
      visible: { value: visiblePermissions, twoWay: true }
    },
    outputs: { visibleChange: onVisiblePermissionChange },
    componentKey: 'PermissionManagement.PermissionManagementComponent'
  }"
  [(visible)]="visiblePermissions"
  [providerKey]="providerKey"
  [hideBadges]="true"
  providerName="U"
  [entityDisplayName]="entityDisplayName"
>
</abp-permission-management>

<abp-claim-modal [(visible)]="visibleClaims" [subject]="claimSubject"></abp-claim-modal>

<abp-modal [(visible)]="isSetPasswordModalVisible" [busy]="modalBusy">
  <ng-template #abpHeader>
    <h3>{{ 'AbpIdentity::SetPassword' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="setPasswordForm" (ngSubmit)="setPassword()" validateOnSubmit>
      <div class="mt-2 fade-in-top">
        <div class="mb-3">
          <label class="form-label" for="new-password">{{
            'AbpIdentity::Password' | abpLocalization
          }}</label>
          <div class="input-group">
            <div class="col-10 p-0">
              <input
                type="text"
                id="new-password"
                class="form-control"
                formControlName="newPassword"
                autofocus
              />
            </div>

            <button
              class="btn btn-secondary"
              id="generate-random-password-button"
              type="button"
              (click)="generatePassword()"
            >
              <i class="fa fa-refresh"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpIdentity::Cancel' | abpLocalization }}
    </button>
    <abp-button
      iconClass="fa fa-check"
      (click)="setPassword()"
      [disabled]="setPasswordForm?.invalid"
      >{{ 'AbpIdentity::Save' | abpLocalization }}</abp-button
    >
  </ng-template>
</abp-modal>

<abp-modal [(visible)]="isLockModalVisible" [busy]="modalBusy">
  <ng-template #abpHeader>
    <h3>{{ 'AbpIdentity::Lock' | abpLocalization }} - {{ selected.userName }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="lockForm" (ngSubmit)="lock()" validateOnSubmit>
      <div class="mt-2 fade-in-top">
        <div class="mb-3" [formGroup]="lockForm">
          <abp-extensible-date-time-picker [prop]="dateTimePickerProps"></abp-extensible-date-time-picker>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpIdentity::Cancel' | abpLocalization }}
    </button>
    <abp-button
      iconClass="fa fa-check"
      (click)="lock()"
      [disabled]="lockForm?.invalid || modalBusy"
      >{{ 'AbpIdentity::Save' | abpLocalization }}</abp-button
    >
  </ng-template>
</abp-modal>

<abp-modal [(visible)]="twoFactor.isModalVisible" [busy]="modalBusy">
  <ng-template #abpHeader>
    <h3>{{ 'AbpIdentity::TwoFactor' | abpLocalization }} - {{ selected.userName }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <div class="mt-2 fade-in-top">
      <div class="form-check mb-2">
        <input
          type="checkbox"
          class="form-check-input"
          id="two-factor-enabled"
          [(ngModel)]="twoFactor.checkboxValue"
        />
        <label class="form-check-label" for="two-factor-enabled">{{
          'AbpIdentity::DisplayName:TwoFactorEnabled' | abpLocalization
        }}</label>
      </div>
    </div>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpIdentity::Cancel' | abpLocalization }}
    </button>
    <abp-button iconClass="fa fa-check" (click)="setTwoFactor()" [disabled]="modalBusy">{{
      'AbpIdentity::Save' | abpLocalization
    }}</abp-button>
  </ng-template>
</abp-modal>

<abp-modal [(visible)]="visibleSessions">
  <ng-template #abpHeader>
    <h3>Sessions</h3>
  </ng-template>
  <ng-template #abpBody>

  </ng-template>

  <ng-template #abpFooter>

  </ng-template>
</abp-modal>