import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { identityEntityPropContributors } from './entity-prop-contributors/identity-entity-prop-contributors';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  //   canActivate: [authGuard, permissionGuard],
  // },
  // {
  //   path: 'premium-reporting',
  //   loadChildren: () =>
  //     import('@sestek/premium-reporting').then(m => m.PremiumReportingModule.forLazy()),
  // },
  {
    path: 'account',
    loadChildren: () =>
      import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'identity',
    loadChildren: () =>
      import('@volo/abp.ng.identity').then(m =>
        m.IdentityModule.forLazy({
          entityPropContributors: identityEntityPropContributors
        })
      ),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then(m => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    loadChildren: () => import('@volo/abp.ng.saas').then(m => m.SaasModule.forLazy()),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'openiddict',
    loadChildren: () =>
      import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then(m =>
        m.TextTemplateManagementModule.forLazy()
      ),
  },

  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'accounts',
    loadChildren: () => import('./accounts/account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'products',
    loadChildren: () => import('./products/product/product.module').then(m => m.ProductModule),
  },
  /*{
    path: 'subscriptions',
    loadChildren: () =>
      import('./subscriptions/subscription/subscription.module').then(m => m.SubscriptionModule),
  },*/
  {
    path: 'subscription-products',
    loadChildren: () =>
      import('./subscription-products/subscription-product/subscription-product.module').then(
        m => m.SubscriptionProductModule
      ),
  },
  {
    path: 'product-options',
    loadChildren: () =>
      import('./product-options/product-option/product-option.module').then(
        m => m.ProductOptionModule
      ),
  },
  {
    path: 'subscription-product-options',
    loadChildren: () =>
      import(
        './subscription-product-options/subscription-product-option/subscription-product-option.module'
      ).then(m => m.SubscriptionProductOptionModule),
  },
  {
    path: 'license-usage-logs',
    loadChildren: () =>
      import('./license-usage-logs/license-usage-log/license-usage-log.module').then(
        m => m.LicenseUsageLogModule
      ),
  },
  {
    path: 'license-usage-reports',
    loadChildren: () =>
      import('./license-usage-reports/license-usage-report/license-usage-report.module').then(
        m => m.LicenseUsageReportModule
      ),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications/notification/notification.module').then(
        m => m.NotificationModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
