<div class="text-right">
  <button class="btn btn-sm btn-primary mb-2" (click)="openModal()">
    <i class="fas fa-plus mr-1"></i> {{ 'AbpIdentity::AddMember' | abpLocalization }}
  </button>
</div>

<abp-extensible-table
  [data]="currentOrganizationUnits.items"
  [recordsTotal]="currentOrganizationUnits.totalCount"
  [list]="list"
></abp-extensible-table>

<abp-modal [(visible)]="isModalVisible" [busy]="isModalBusy">
  <ng-template #abpHeader>
    <h3>{{ 'AbpIdentity::SelectUsers' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <app-ldm-organization-members-modal-body
      [checkedUnits]="checkedUnits"
      [isCheckboxDisabled]="isCheckboxDisabled"
    ></app-ldm-organization-members-modal-body>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpIdentity::Cancel' | abpLocalization }}
    </button>
    <abp-button iconClass="fa fa-check" (click)="addUnits()">{{
      'AbpIdentity::Save' | abpLocalization
    }}</abp-button>
  </ng-template>
</abp-modal>
