import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonConstants {
  static readonly Int32Max: number = 2147483647;
  static readonly MaxResultCount: number = 1000;

  static readonly SuperAdminRoleName: string = 'admin';
  static readonly UnitAdminRoleName: string = 'unitadmin';
  static readonly StandartRoleName: string = 'standard';
  static readonly Support: string = 'support';
}
