import { BaseNode, DropEvent, TreeAdapter } from '@abp/ng.components/tree';
import { ListResultDto } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import {
  EXTENSIONS_IDENTIFIER,
  FormPropData,
  generateFormFromProps,
} from '@abp/ng.components/extensible';
import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  GetOrganizationUnitInput,
  OrganizationUnitService,
  OrganizationUnitUpdateDto,
  OrganizationUnitWithDetailsDto,
} from '@volo/abp.ng.identity/proxy';
import { finalize, tap } from 'rxjs/operators';
import { LdmOrganizationMembersComponent } from './ldm-organization-members/ldm-organization-members.component';
import { eIdentityComponents } from '@volo/abp.ng.identity';
import { OrganizationUnitTypeEnumOptions } from '@proxy/organization-unit/organization-unit-type.enum';

@Component({
  selector: 'app-ldm-organization-units',
  templateUrl: './ldm-organization-units.component.html',
  providers: [
    {
      provide: EXTENSIONS_IDENTIFIER,
      useValue: eIdentityComponents.OrganizationUnits,
    },
  ],
  styles: [
    `
      .fs-15px {
        font-size: 15px;
      }
      .mat-mdc-radio-button ~ .mat-mdc-radio-button {
        margin-left: 16px;
      }
    `,
  ],
})
export class LdmOrganizationUnitsComponent implements OnInit {
  organizationUnits: OrganizationUnitWithDetailsDto[] = [];
  filteredOrganizationUnits: OrganizationUnitWithDetailsDto[] = [];
  searchTerm: string = '';
  selectedUnit: OrganizationUnitWithDetailsDto;
  nodes = [];
  treeAdapter: TreeAdapter;
  expandedKeys: string[] = [];
  isNodeModalVisible: boolean;
  isModalBusy: boolean;
  organizationMembersKey = eIdentityComponents.OrganizationMembers;
  organizationRolesKey = eIdentityComponents.OrganizationRoles;
  loading: boolean;

  nodeForm: FormGroup;
  organizationUnitTypeOptions = OrganizationUnitTypeEnumOptions;

  constructor(
    protected injector: Injector,
    protected organizationUnitService: OrganizationUnitService,
    protected fb: FormBuilder,
    protected confirmation: ConfirmationService
  ) {}

  ngOnInit() {
    this.get();
  }

  get = () => {
    this.loading = true;

    const input: GetOrganizationUnitInput = {
      filter: this.searchTerm, // Pass the searchTerm as the filter
      maxResultCount: 1000,
    };
    
    this.organizationUnitService
      .getList(input) // Pass searchTerm to the backend
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((res: ListResultDto<OrganizationUnitWithDetailsDto>) => {
        this.organizationUnits = res.items;
        this.updateTree();
      });
  };

  updateTree() {
    this.treeAdapter = new TreeAdapter(
      this.organizationUnits as OrganizationUnitWithDetailsDto[] as BaseNode[]
    );
    this.nodes = this.treeAdapter.getTree();
    this.expandedKeys = [...this.organizationUnits.map(x => x.id)];
  }

  onSearchTermChange() {
    this.get(); // Trigger backend call with searchTerm
  }

  buildForm(selected = {} as OrganizationUnitWithDetailsDto) {
    const data = new FormPropData(this.injector, selected);
    this.nodeForm = generateFormFromProps(data);
    this.nodeForm.addControl('parentId', new FormControl(undefined));
    this.nodeForm.addControl('id', new FormControl(undefined));
    let extraProperties = data.record?.extraProperties;
    (this.nodeForm.controls["extraProperties"] as FormGroup)
      .addControl('OrganizationUnitType', new FormControl(extraProperties?.OrganizationUnitType, [Validators.required, Validators.min(1)]))
  }

  add() {
    this.buildForm();
    this.isNodeModalVisible = true;
  }

  edit(selected: OrganizationUnitWithDetailsDto) {
    this.buildForm(selected);
    this.nodeForm.patchValue({
      parentId: '',
      displayName: selected.displayName,
      id: selected.id,
    });
    this.isNodeModalVisible = true;
  }

  addSubUnit({ id }: OrganizationUnitWithDetailsDto) {
    this.buildForm();
    this.nodeForm.patchValue({ parentId: id, displayName: '', id: undefined });
    this.isNodeModalVisible = true;
    this.expandedKeys = this.expandedKeys.concat(id);
  }

  save() {
    if (this.nodeForm.invalid) return;

    const { id, ...form } = this.nodeForm.value;

    const request = id
      ? this.organizationUnitService.update(id, {
          displayName: form.displayName,
          extraProperties: form.extraProperties
        } as OrganizationUnitUpdateDto)
      : this.organizationUnitService.create(form);

    this.isModalBusy = true;
    request.pipe(finalize(() => (this.isModalBusy = false))).subscribe(() => {
      this.get();
      this.isNodeModalVisible = false;
    });
  }

  delete({ id, displayName }: OrganizationUnitWithDetailsDto) {
    this.confirmation
      .warn('AbpIdentity::OrganizationUnitDeletionConfirmationMessage', 'AbpIdentity::AreYouSure', {
        messageLocalizationParams: [displayName],
      })
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          this.organizationUnitService
            .delete(id)
            .pipe(
              tap(_ => {
                if (id === this.selectedUnit?.id) {
                  this.selectedUnit = null;
                }
              })
            )
            .subscribe(() => this.get());
        }
      });
  }

  getParentName(parentId: string) {
    const parent = this.organizationUnits.find(unit => unit.id === parentId);

    if (!parent) return '';

    return parent.displayName;
  }

  onDrop(event: DropEvent) {
    if (!event.node) return;

    let parentId = event.node.key;
    if (!event.node.origin.parentId && event.pos === -1) {
      parentId = null;
    }

    this.move(event.dragNode.key, parentId);
  }

  move(id: string, newParentId: string) {
    this.organizationUnitService.move(id, { newParentId }).subscribe(this.get);
  }
}
