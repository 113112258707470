<ng-select
    style="max-width: 450px; min-width: 250px"
    [disabled]="isDisabled"
    [(ngModel)]="value"
    [ngModelOptions]="{ standalone: true }"
    [items]="flatOrganizationUnits"
    bindLabel="displayName"
    bindValue="id"
    [multiple]="multiSelection"
    [closeOnSelect]="false"
    (change)="onOrganizationUnitChange($event)"
  >
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <input
        id="item-{{ index }}"
        type="checkbox"
        [ngModel]="item$.selected"
        [ngModelOptions]="{ standalone: true }"
      />
      {{ convertUnitCodeToIndentation(item.code) }} {{ item.displayName }}
    </ng-template>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true"
        >×</span
      >
      <span class="ng-value-label" [attr.title]="item.displayName">{{
        item.displayName
      }}</span>
    </ng-template>
</ng-select>