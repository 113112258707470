import { ListResultDto, RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { OrganizationUnitDto, OrganizationUnitWithDetailsDto } from '@volo/abp.ng.identity/proxy';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiName = 'Default';

  getAvailableOrganizationUnitsForUser = (id: string) =>
    this.restService.request<any, ListResultDto<OrganizationUnitWithDetailsDto>>(
      {
        method: 'GET',
        url: `/api/app/users/${id}/available-organization-units`,
      },
      { apiName: this.apiName }
    );

  getOrganizationUnitOfUser = (id: string) =>
    this.restService.request<any, OrganizationUnitDto>(
      {
        method: 'GET',
        url: `/api/app/users/${id}/organization-unit`,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
