import { BaseNode, TreeAdapter } from '@abp/ng.components/tree';
import { AbstractNgModelComponent, TreeNode } from '@abp/ng.core';
import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  IdentityUserService,
  OrganizationUnitDto,
  OrganizationUnitWithDetailsDto,
} from '@volo/abp.ng.identity/proxy';

@Component({
  selector: 'app-organization-unit-selector',
  templateUrl: './organization-unit-selector.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganizationUnitSelectorComponent),
      multi: true,
    },
  ],
})
export class OrganizationUnitSelectorComponent
  extends AbstractNgModelComponent<string>
  implements OnInit
{
  @Output()
  organizationUnitChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  isDisabled = false;

  @Input()
  multiSelection = false;

  flatOrganizationUnits: OrganizationUnitDto[];

  constructor(public userService: IdentityUserService, public injector: Injector) {
    super();
  }

  ngOnInit(): void {
    this.userService.getAvailableOrganizationUnits().subscribe(res => {
      var treeAdapter = new TreeAdapter(res.items as BaseNode[]);
      var tree = treeAdapter.getTree();
      this.sortTreeItemsAlphabetic(tree);
      this.flatOrganizationUnits = [];
      this.buildFlatOrganizationList(tree, this.flatOrganizationUnits);
    });
  }

  buildFlatOrganizationList(tree: any, flatOrganizationUnits: OrganizationUnitDto[]) {
    tree.forEach(element => {
      flatOrganizationUnits.push(element.entity as OrganizationUnitWithDetailsDto);
      if (element.children && element.children.length > 0) {
        this.buildFlatOrganizationList(element.children, flatOrganizationUnits);
      }
    });
  }

  sortTreeItemsAlphabetic(tree: any) {
    tree.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });

    tree.forEach(element => {
      if (element && element.children && element.children.length > 0) {
        this.sortTreeItemsAlphabetic(element.children);
      }
    });
  }

  convertUnitCodeToIndentation(unitCode: string) {
    const oneLevelOfUnitCodeLength = 5;
    const trimmedUnitCode = unitCode.split('.').join('');
    const indent = trimmedUnitCode.length / oneLevelOfUnitCodeLength;

    const rootUnit = this.flatOrganizationUnits.find(o => !o.parentId);
    let rootUnitIndent = 0;
    if (rootUnit) {
      const trimmedRootUnitCode = rootUnit.code.split('.').join('');
      rootUnitIndent = trimmedRootUnitCode.length / oneLevelOfUnitCodeLength;
    }

    return '-'.repeat((indent - rootUnitIndent) * 2);
  }

  onOrganizationUnitChange(item: any) {
    this.organizationUnitChange.emit(item);
  }
}
