import {
  ConfigStateService,
  PagedResultDto,
  ReplaceableComponentsService,
  RoutesService,
} from '@abp/ng.core';
import { Component } from '@angular/core';
import { eIdentityComponents } from '@volo/abp.ng.identity';
import { LdmOrganizationUnitsComponent } from './ldm-organization-units/ldm-organization-units.component';
import { LdmOrganizationMembersComponent } from './ldm-organization-units/ldm-organization-members/ldm-organization-members.component';
import { LdmUsersComponent } from './ldm-users/ldm-users.component';
import { eThemeSharedRouteNames } from '@abp/ng.theme.shared';
import { CommonConstants } from './constants/common-constants';
import { NavItemsComponent } from './shared/components/nav-items/nav-items.component';
import { eThemeLeptonComponents } from '@volo/abp.ng.theme.lepton';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})
export class AppComponent {
  constructor(
    private replaceableComponents: ReplaceableComponentsService,
    private routes: RoutesService,
    private config: ConfigStateService
  ) {
    const currentUserRoles: string[] = this.config.getDeep(['currentUser', 'roles']);
    if (
      !currentUserRoles.includes(CommonConstants.SuperAdminRoleName) &&
      !currentUserRoles.includes(CommonConstants.UnitAdminRoleName) &&
      !currentUserRoles.includes(CommonConstants.Support)
    ) {
      this.routes.patch(eThemeSharedRouteNames.Administration, { invisible: true });
    }
  }

  ngOnInit() {
    this.replaceableComponents.add({
      component: LdmUsersComponent,
      key: eIdentityComponents.Users,
    });

    this.replaceableComponents.add({
      component: LdmOrganizationUnitsComponent,
      key: eIdentityComponents.OrganizationUnits,
    });

    this.replaceableComponents.add({
      component: NavItemsComponent,
      key: eThemeLeptonComponents.NavItems,
    });

    // this.replaceableComponents.add({
    //   component: LdmOrganizationMembersComponent,
    //   key: eIdentityComponents.OrganizationMembers,
    // });
  }
}
