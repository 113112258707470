import { ListService } from '@abp/ng.core';
import { EXTENSIONS_IDENTIFIER } from '@abp/ng.components/extensible';
import { Component } from '@angular/core';
import { eIdentityComponents } from '@volo/abp.ng.identity';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';
import { OrganizationUnitConfig, ORGANIZATION_UNIT_CONFIG } from '@volo/abp.ng.identity';
import { LdmAbstractOrganizationUnitComponent } from '../ldm-abstract-organization-unit/ldm-abstract-organization-unit.component';

@Component({
  selector: 'app-ldm-organization-members',
  templateUrl: './ldm-organization-members.component.html',
  providers: [
    ListService,
    {
      provide: EXTENSIONS_IDENTIFIER,
      useValue: eIdentityComponents.OrganizationMembers,
    },
    {
      provide: ORGANIZATION_UNIT_CONFIG,
      useValue: {
        getCurrentUnitsMethodName: 'getMembers',
        addUnitsMethodName: 'addMembers',
        addUnitsBodyPropName: 'userIds',
        deleteMethodName: 'removeMember',
        deletionLocalizationKey: 'AbpIdentity::RemoveUserFromOuWarningMessage',
      } as OrganizationUnitConfig,
    },
  ],
})
export class LdmOrganizationMembersComponent extends LdmAbstractOrganizationUnitComponent<IdentityUserDto> {}
