import { eIdentityComponents, IdentityEntityPropContributors } from '@volo/abp.ng.identity';
import { EntityProp, EntityPropList, ePropType } from '@abp/ng.components/extensible';
import { of } from 'rxjs';
import { ObjectExtensionConstants } from '../constants/object-extension-constants';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';

const organizationUnitProp = new EntityProp<IdentityUserDto>({
  type: ePropType.String,
  name: ObjectExtensionConstants.IdentityUserOrganizationUnitDisplayName,
  isExtra: true,
  displayName: 'AbpIdentity::OrganizationUnits',
  sortable: false,
  columnWidth: 250,
  valueResolver: data => {
    var cellContent: string;
    if (
      data.record.extraProperties &&
      data.record.extraProperties[ObjectExtensionConstants.IdentityUserOrganizationUnitDisplayName]
    ) {
      cellContent =
        data.record.extraProperties[
          ObjectExtensionConstants.IdentityUserOrganizationUnitDisplayName
        ];
    }
    return of(cellContent);
  },
});

export function namePropContributor(propList: EntityPropList<IdentityUserDto>) {
  propList.addAfter(organizationUnitProp, 'phoneNumber', (value, name) => value.name === name);
}

export const identityEntityPropContributors: IdentityEntityPropContributors = {
  // enum indicates the page to add contributors to
  [eIdentityComponents.Users]: [
    namePropContributor,
    // You can add more contributors here
  ],
};
