import { Component, OnInit } from '@angular/core';
import * as _uiVersionJson from 'src/version.json';
import { VersionInfoDto } from '../../models/version-info.model';
import { VersionInfoService } from '../../services/version-info.service';

@Component({
  selector: 'app-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss'],
})
export class VersionInfoComponent implements OnInit {
  private uiVersionJson = _uiVersionJson;

  versionInfo = {
    uiVersion: '',
    uiUpdateDate: '',
    version: '',
    updateDate: '',
  };

  constructor(private versionInfoService: VersionInfoService) {}

  ngOnInit() {
    if (this.uiVersionJson != null) {
      this.versionInfo.uiVersion = this.uiVersionJson.version;
      this.versionInfo.uiUpdateDate = this.uiVersionJson.updateDate;
    }
    this.versionInfoService.getVersionInfo().subscribe(response => {
      this.versionInfo.version = response.version;
      this.versionInfo.updateDate = response.updateDate;
    });
  }
}
