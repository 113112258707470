import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToasterService } from '@abp/ng.theme.shared';
import { ValidationErrorMessageModel } from '../models/validation-error-message.model';

@Injectable()
export class HttpErrorResponseInterceptor implements HttpInterceptor {
  constructor(private toastr: ToasterService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        let errors: ValidationErrorMessageModel[] = [];

        try {
          errors = response.error.error.validationErrors;
        } catch (e) {
          errors = [];
        }
        if (response.status === HttpStatusCode.BadRequest && errors.length > 0) {
          const message = this.createValidationMessage(errors);

          // TODO: I18N
          this.toastr.error(message, 'Validation Error');
        } else if (response.status === HttpStatusCode.Forbidden && response.error != null) {
          if (response.error.error?.data?.toastr === false) {
            return throwError(response);
          }
          // TODO: I18N
          if (response.error.error && response.error.error.message) {
            this.toastr.error(response.error.error.message, '::Error');
          } else {
            return throwError(response);
          }
        } else if (response.status === HttpStatusCode.NotFound && response.error != null) {
          if (response.error.error?.data?.toastr === false) {
            return throwError(response);
          }
          // TODO: I18N
          if (response.error.error && response.error.error.message) {
            this.toastr.error(response.error.error.message, '::Error');
          }
        } else {
          // TODO: I18N
          this.toastr.error('An unexpected error has occured. Please try again.', 'Server Error');
        }

        //return throwError(response);
      })
    ) as Observable<HttpEvent<any>>;
  }

  private createValidationMessage(errors: ValidationErrorMessageModel[]): string {
    let result = '';

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < errors.length; i++) {
      result += errors[i].message + '<br/>';
    }

    return result;
  }
}
