import {
  AuthService,
  ConfigStateService,
  CurrentTenantDto,
  CurrentUserDto,
  EnvironmentService,
  SessionStateService,
} from '@abp/ng.core';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { LanguageDto } from '@volo/abp.ng.language-management/proxy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import snq from 'snq';

@Component({
  selector: 'app-nav-items',
  templateUrl: './nav-items.component.html',
  styleUrls: ['./nav-items.component.scss'],
})
export class NavItemsComponent implements OnInit {
  @ViewChild('currentUser', { static: false, read: TemplateRef }) currentUserRef: TemplateRef<any>;
  @ViewChild('language', { static: false, read: TemplateRef }) languageRef: TemplateRef<any>;
  @Input() smallScreen: boolean;
  @Input() isCollapseNavbarTool: boolean;
  currentUser$: Observable<CurrentUserDto>;
  selectedTenant$: Observable<CurrentTenantDto>;
  languages$: Observable<LanguageDto[]>;

  constructor(
    private store: Store,
    private authService: AuthService,
    private modalService: NgbModal,
    private sessionStateService: SessionStateService,
    private configState: ConfigStateService,
    private environment: EnvironmentService,
    private router: Router
  ) {}

  get defaultLanguage$(): Observable<{ displayName: string; flagIcon: string }> {
    return this.languages$.pipe(
      map(languages => {
        const lang: Partial<LanguageDto> = snq(
          () =>
            languages.find(
              language => language.cultureName === this.sessionStateService.getLanguage()
            ),
          {} as Partial<LanguageDto>
        );
        return {
          displayName: lang.displayName || '',
          flagIcon: lang.flagIcon,
        };
      })
    );
  }

  get dropdownLanguages$(): Observable<LanguageDto[]> {
    return this.languages$.pipe(
      map(
        languages =>
          snq(() =>
            languages.filter(lang => {
              if (lang.cultureName === 'tr' || lang.cultureName === 'en') {
                return lang.cultureName !== this.sessionStateService.getLanguage();
              }
            })
          ),
        []
      )
    );
  }

  get fullscreen(): boolean {
    return window.innerHeight === screen.height;
  }

  ngOnInit() {
    this.languages$ = this.configState.getDeep$('localization.languages');
    this.currentUser$ = this.configState.getDeep$('currentUser');
    this.selectedTenant$ = this.sessionStateService.getTenant$();
  }

  changeLang(cultureName: string) {
    if (cultureName === 'ar') {
      setTimeout(() => location.reload(), 500);
    }
    this.store.dispatch(this.sessionStateService.setLanguage(cultureName));
  }

  logout() {
    this.authService.logout().subscribe();
  }

  openVersionModal(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'md',
    });
  }

  onClickManageProfile() {
    this.router.navigate(['account/manage']);
  }

  get securityLogsUrl() {
    return `${
      this.environment.getEnvironment().oAuthConfig.issuer
    }/Account/securitylogs?returnUrl=${window.location.href}`;
  }
}
