import { BaseNode, TreeAdapter } from '@abp/ng.components/tree';
import {
  ConfigStateService,
  generatePassword,
  ListService,
  mapEnumToOptions,
  PagedResultDto,
} from '@abp/ng.core';
import {
  Confirmation,
  ConfirmationService,
  getPasswordValidators,
  ToasterService,
} from '@abp/ng.theme.shared';
import {
  EXTENSIONS_IDENTIFIER,
  FormProp,
  FormPropData,
  ePropType,
  generateFormFromProps,
} from '@abp/ng.components/extensible';
import {
  Component,
  Injector,
  OnInit,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { finalize, pluck, switchMap, take, tap } from 'rxjs/operators';
import snq from 'snq';
import { DEFAULT_USERS_CREATE_FORM_PROPS, DEFAULT_USERS_EDIT_FORM_PROPS, DEFAULT_USERS_ENTITY_ACTIONS, UsersComponent, eIdentityComponents } from '@volo/abp.ng.identity';
import { identityTwoFactorBehaviourOptions } from '@volo/abp.ng.identity';
import {
  IdentityRoleService,
  IdentityUserService,
  OrganizationUnitService,
} from '@volo/abp.ng.identity/proxy';
import {
  GetIdentityUsersInput,
  IdentityRoleDto,
  IdentityUserDto,
  OrganizationUnitDto,
  OrganizationUnitWithDetailsDto,
} from '@volo/abp.ng.identity/proxy';
import { UserService } from '@proxy/users/user.service';
import { ObjectExtensionConstants } from '../constants/object-extension-constants';
import { CommonConstants } from '../constants/common-constants';

enum UserLockDurationType {
  Second = 1,
  Minute = 60,
  Hour = 60 * 60,
  Day = 60 * 60 * 24,
  Month = 60 * 60 * 24 * 30,
  Year = 60 * 60 * 24 * 365,
}

@Component({
  selector: 'app-ldm-users',
  templateUrl: './ldm-users.component.html',
  providers: [
    ListService,
    {
      provide: EXTENSIONS_IDENTIFIER,
      useValue: eIdentityComponents.Users,
    },
    {
      provide: UsersComponent,
      useExisting: LdmUsersComponent,
    },
  ],
  styles: [
    `
      .mh-35 {
        max-height: 35px;
      }
    `,
  ],
})
export class LdmUsersComponent implements OnInit {
  data: PagedResultDto<IdentityUserDto> = { items: [], totalCount: 0 };

  @ViewChild('modalContent')
  modalContent: TemplateRef<any>;

  form: FormGroup;

  setPasswordForm = this.fb.group({
    newPassword: ['', [Validators.required, ...getPasswordValidators(this.injector)]],
  });

  selected: IdentityUserDto;

  selectedUserRoles: IdentityRoleDto[];

  roles: IdentityRoleDto[];

  selectedOrganizationUnits: OrganizationUnitDto[];

  flatOrganizationUnits: OrganizationUnitDto[];

  visiblePermissions: boolean = false;

  providerKey: string;

  isModalVisible: boolean;

  isSetPasswordModalVisible: boolean;

  modalBusy: boolean = false;

  visibleClaims: boolean = false;

  visibleSessions: boolean = false;

  claimSubject = {} as { id: string; type: 'roles' | 'users' };

  filters = {} as GetIdentityUsersInput;

  organization = {
    response: {} as PagedResultDto<OrganizationUnitWithDetailsDto>,
    nodes: [],
    checkedKeys: [],
    expandedKeys: [],
    selectFn: () => false,
  };

  isLockModalVisible: boolean;

  twoFactor = {
    isModalVisible: false,
    checkboxValue: false,
    isOptional: false,
  };

  lockForm = this.fb.group({
    lockoutEnd: [new Date(), [Validators.required]],
  });

  dateTimePickerProps = {
    defaultValue: new Date(),
    displayName: 'AbpIdentity::DisplayName:LockoutEnd',
    validators: () => [Validators.required],
    name: 'lockoutEnd',
    id: 'lockout-end',
    type: ePropType.DateTime,
  } as Partial<FormProp>;

  entityDisplayName: string | undefined;

  lockDurationTypeOptions = mapEnumToOptions(UserLockDurationType);

  trackByFn: TrackByFunction<AbstractControl> = (index, item) => Object.keys(item)[0] || index;

  get roleGroups(): FormGroup[] {
    return snq(() => (this.form.get('roleNames') as FormArray).controls as FormGroup[], []);
  }

  onVisiblePermissionChange = (value: boolean) => {
    this.visiblePermissions = value;
  };

  constructor(
    public readonly list: ListService<GetIdentityUsersInput>,
    public confirmationService: ConfirmationService,
    public service: IdentityUserService,
    public fb: FormBuilder,
    public toasterService: ToasterService,
    public injector: Injector,
    public configState: ConfigStateService,
    public customUserService: UserService,
    private config: ConfigStateService,
    public roleService: IdentityRoleService,
    public organizationUnitService: OrganizationUnitService
  ) {}

  ngOnInit() {
    const { key } = identityTwoFactorBehaviourOptions[0];
    this.twoFactor.isOptional =
    this.configState.getFeature('Identity.TwoFactor') === key &&
    this.configState.getSetting('Abp.Identity.TwoFactor.Behaviour') === key;

    //ABP 8.0 @volo\abp.ng.identity has CSS issue on name and surname fields, once this is fixed on their side, we will need to remove the css styles from action list
    const nameProp = DEFAULT_USERS_EDIT_FORM_PROPS.find(prop => prop.name === 'name');
    if (nameProp) {
      Object.defineProperty(nameProp, 'group', {
        value: null, 
        writable: true,
        configurable: true,
      });
    }

    const surnameProp = DEFAULT_USERS_EDIT_FORM_PROPS.find(prop => prop.name === 'surname');
    if (surnameProp) {
      Object.defineProperty(surnameProp, 'group', {
        value: null, 
        writable: true,
        configurable: true,
      });
    }

    //Our LDM Application uses custom Users component page and on ABP 8.0, there are new two menus which is not required for us, so for now, we need to remove them.
    const viewDetailsEntityActionIndex = DEFAULT_USERS_ENTITY_ACTIONS.findIndex(x => x.permission == 'AbpIdentity.Users.ViewDetails');
    if (viewDetailsEntityActionIndex != -1) {
      DEFAULT_USERS_ENTITY_ACTIONS.splice(viewDetailsEntityActionIndex, 1);
    }

    const sessionsEntityActionIndex = DEFAULT_USERS_ENTITY_ACTIONS.findIndex(x => x.permission == 'AbpIdentity.Sessions');
    if (sessionsEntityActionIndex != -1) {
      DEFAULT_USERS_ENTITY_ACTIONS.splice(sessionsEntityActionIndex, 1);
    }

    this.hookToQuery();
  }

  clearFilters() {
    this.filters = {} as GetIdentityUsersInput;
  }

  convertUnitCodeToIndentation(unitCode: string) {
    const oneLevelOfUnitCodeLength = 5;
    const trimmedUnitCode = unitCode.split('.').join('');
    const indent = trimmedUnitCode.length / oneLevelOfUnitCodeLength;

    const rootUnit = this.flatOrganizationUnits.find(o => !o.parentId);
    let rootUnitIndent = 0;
    if (rootUnit) {
      const trimmedRootUnitCode = rootUnit.code.split('.').join('');
      rootUnitIndent = trimmedRootUnitCode.length / oneLevelOfUnitCodeLength;
    }

    return '-'.repeat((indent - rootUnitIndent) * 2);
  }

  private hookToQuery() {
    this.list
      .hookToQuery(query => this.service.getList({ ...query, ...this.filters }))
      .subscribe(res => {
        this.data = res;
      });
  }

  atLeastOneItemSelected() {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const atLeastOneItemIsChecked = formArray.controls.some(c => Object.values(c.value)[0]);
      return atLeastOneItemIsChecked ? null : { required: true };
    };

    return validator;
  }

  buildForm() {
    const data = new FormPropData(this.injector, this.selected);
    this.form = generateFormFromProps(data);

    this.form.addControl('userUnit', this.fb.control(null, Validators.required));

    this.service.getAssignableRoles().subscribe(({ items }) => {
      const currentUserRoles: string[] = this.config.getDeep(['currentUser', 'roles']);
      const superAdminRoleIndex = items.findIndex(
        i => i.name === CommonConstants.SuperAdminRoleName
      );

      if (
        superAdminRoleIndex > -1 &&
        !currentUserRoles.includes(CommonConstants.SuperAdminRoleName)
      ) {
        items.splice(superAdminRoleIndex, 1);
      }

      this.roles = items;

      this.form.addControl(
        'roleNames',
        this.fb.array(
          this.roles.map(role =>
            this.fb.group({
              [role.name]: [
                this.selected.id
                  ? !!snq(() => this.selectedUserRoles.find(userRole => userRole.id === role.id))
                  : role.isDefault,
              ],
            })
          ),
          null
        )
      );
    });

    if (this.selected.id) {
      this.service.getOrganizationUnits(this.selected.id).subscribe(res => {
        if (res.length > 0) {
          this.form.patchValue({ userUnit: res[0].id });
        }
      });
    }

    this.service.getAvailableOrganizationUnits().subscribe(res => {
      this.organization.response = res;
      this.organization.nodes = new TreeAdapter(res.items as BaseNode[]).getTree();
      this.organization.expandedKeys = res.items.map(item => item.id);
      this.organization.checkedKeys = this.selectedOrganizationUnits.map(unit => unit.id);

      let flatList = new TreeAdapter(res.items as BaseNode[]).getList();
      this.flatOrganizationUnits = [];
      flatList.forEach(element => {
        this.flatOrganizationUnits.push(element as OrganizationUnitWithDetailsDto);
      });
    });
  }

  openModal() {
    this.buildForm();
    this.isModalVisible = true;
  }

  onAdd() {
    this.selected = {} as IdentityUserDto;

    this.selectedUserRoles = [];
    this.selectedOrganizationUnits = [];
    this.openModal();
  }

  onEdit(id: string) {
    this.service
      .get(id)
      .pipe(
        tap(selectedUser => (this.selected = selectedUser)),
        switchMap(() => this.service.getRoles(id)),
        tap(res => (this.selectedUserRoles = res.items || [])),
        switchMap(() => this.service.getOrganizationUnits(id)),
        tap(res => (this.selectedOrganizationUnits = res)),
        take(1)
      )
      .subscribe(() => this.openModal());
  }

  save() {
    if (!this.form.valid) return;
    this.modalBusy = true;

    const { roleNames } = this.form.value;
    const mappedRoleNames = snq(
      () =>
        roleNames.filter(role => !!role[Object.keys(role)[0]]).map(role => Object.keys(role)[0]),
      []
    );

    const selectedOrganizationUnitIds = this.form.get('userUnit').value
      ? [].concat(this.form.get('userUnit').value)
      : [];

    if (this.form.get('userUnit').value) {
      if (!this.selected.extraProperties) {
        this.selected.extraProperties = {};
      }

      this.selected.extraProperties[ObjectExtensionConstants.IdentityUserOrganizationUnitId] =
        this.form.get('userUnit').value;
    }

    const { id } = this.selected;

    (id
      ? this.service.update(id, {
          ...this.selected,
          ...this.form.value,
          roleNames: mappedRoleNames,
          organizationUnitIds: selectedOrganizationUnitIds,
          extraProperties: this.selected.extraProperties,
        })
      : this.service.create({
          ...this.form.value,
          roleNames: mappedRoleNames,
          organizationUnitIds: selectedOrganizationUnitIds,
          extraProperties: this.selected.extraProperties,
        })
    )
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(() => {
        this.list.get();
        this.isModalVisible = false;
      });
  }

  delete(id: string, userName: string) {
    this.confirmationService
      .warn('AbpIdentity::UserDeletionConfirmationMessage', 'AbpIdentity::AreYouSure', {
        messageLocalizationParams: [userName],
      })
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          this.service.delete(id).subscribe(() => this.list.get());
        }
      });
  }

  onManageClaims(id: string) {
    this.claimSubject = {
      id,
      type: 'users',
    };

    this.visibleClaims = true;
  }

  openSessionsModal(user: IdentityUserDto) {
    this.visibleSessions = true;
  }

  unlock(id: string) {
    this.service.unlock(id).subscribe(() => {
      this.toasterService.success('AbpIdentity::UserUnlocked');
      this.list.get();
    });
  }

  openPermissionsModal(providerKey: string, userName?: string) {
    this.providerKey = providerKey;
    this.entityDisplayName = userName;
    setTimeout(() => {
      this.visiblePermissions = true;
    }, 0);
  }

  setPassword() {
    if (this.setPasswordForm.invalid) return;

    this.modalBusy = true;
    this.service
      .updatePassword(this.selected.id, this.setPasswordForm.value as any)
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(() => {
        this.isSetPasswordModalVisible = false;
        this.selected = {} as IdentityUserDto;
        this.setPasswordForm.reset();
      });
  }

  generatePassword() {
    this.setPasswordForm.get('newPassword').setValue(generatePassword());
  }

  lock() {
    const { lockoutEnd } = this.lockForm.value;

    this.modalBusy = true;
    this.service
      .lock(this.selected.id, lockoutEnd.toLocaleString())
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(() => {
        this.isLockModalVisible = false;
        this.lockForm.reset({
          lockoutEnd: new Date(),
        });
        this.list.get();
      });
  }

  setTwoFactor() {
    this.modalBusy = true;
    this.service
      .setTwoFactorEnabled(this.selected.id, this.twoFactor.checkboxValue)
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(() => (this.twoFactor.isModalVisible = false));
  }
}
