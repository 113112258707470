import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const PRODUCT_OPTIONS_PRODUCT_OPTION_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    // routes.add([
    //   {
    //     path: '/product-options',
    //     iconClass: 'fas fa-file-alt',
    //     name: '::Menu:ProductOptions',
    //     layout: eLayoutType.application,
    //     requiredPolicy: 'Ldm.ProductOptions',
    //   },
    // ]);
  };
}
