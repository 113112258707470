<nav class="navbar navbar-expand-lg user-nav-mobile">
  <div
    class="navbar-collapse d-lg-block toolbar-nav-wrapper collapse"
    id="navbarToolbar"
    [class.show]="smallScreen"
    [class.abp-collapsed-height]="smallScreen"
    [class.abp-mh-100]="smallScreen && isCollapseNavbarTool"
  >
    <ul class="navbar-nav toolbar-nav ms-auto">
      <li class="nav-item">
        <div class="dropdown" ngbDropdown>
          <a
            *ngIf="defaultLanguage$ | async as defaultLang"
            class="btn"
            role="button"
            id="dropdownMenuLink"
            ngbDropdownToggle
          >
            <span
              class="flag-icon flag-icon-squared flag-icon-{{ defaultLang.flagIcon }}"
              [title]="defaultLang.displayName"
            ></span>
            <span class="current-language-name ms-1">{{ defaultLang.displayName }}</span>
          </a>
          <div
            ngbDropdownMenu
            class="dropdown-menu dropdown-menu-right"
            *ngIf="(dropdownLanguages$ | async)?.length > 0"
          >
            <a
              *ngFor="let lang of (dropdownLanguages$ | async) || []"
              class="dropdown-item pointer"
              (click)="changeLang(lang.cultureName)"
            >
              <span class="flag-icon flag-icon-{{ lang.flagIcon }} flag-icon-squared me-2"></span>
              {{ lang?.displayName }}</a
            >
          </div>
        </div>
      </li>

      <li class="nav-item" *ngIf="currentUser$ | async as user">
        <ng-template #loginBtn>
          <a role="button" routerLink="/account/login" class="btn">{{
            'AbpAccount::Login' | abpLocalization
          }}</a>
        </ng-template>
        <div *ngIf="user.isAuthenticated; else loginBtn" class="dropdown btn-group" ngbDropdown>
          <a ngbDropdownToggle class="btn pointer">
            <img class="user-avatar" src="/assets/images/pp.png" />
            <span class="d-lg-none ml-1 d-inline-important">
              <small *ngIf="(selectedTenant$ | async)?.name as tenantName"
                ><i>{{ tenantName }}</i
                >\</small
              >
              <span>{{ user.userName }}</span>
            </span>
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
            <div class="p-2 row">
              <div class="pe-0 col col-auto">
                <img class="user-avatar-big" src="/assets/images/pp.png" />
              </div>
              <div class="ps-2 col">
                <span>{{ 'AbpAccount::Welcome' | abpLocalization }}</span
                ><br />
                <small *ngIf="(selectedTenant$ | async)?.name as tenantName"
                  ><i>{{ tenantName }}</i
                  >\</small
                >
                <strong>{{ user.userName }}</strong>
              </div>
            </div>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item pointer" (click)="onClickManageProfile()">{{
              'AbpAccount::MyAccount' | abpLocalization
            }}</a>
            <a class="dropdown-item pointer" [href]="securityLogsUrl">{{
              'AbpAccount::MySecurityLogs' | abpLocalization
            }}</a>
            <a class="dropdown-item pointer" (click)="openVersionModal(versionInfoModal)">{{
              '::About' | abpLocalization
            }}</a>
            <a class="dropdown-item pointer" id="logout" (click)="logout()">{{
              'AbpUi::Logout' | abpLocalization
            }}</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>

<ng-template #versionInfoModal let-modal>
  <!-- <div class="modal-header bg-primary">
    <h4 class="modal-title d-flex justify-content-center"><abp-logo></abp-logo></h4>
  </div> -->
  <div class="modal-body">
    <app-version-info></app-version-info>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss()">
      {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </div>
</ng-template>
