import { eLayoutType, RoutesService } from '@abp/ng.core';
import { eThemeSharedRouteNames } from '@abp/ng.theme.shared';
import { APP_INITIALIZER } from '@angular/core';

export const NOTIFICATION_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/notifications',
        parentName: eThemeSharedRouteNames.Administration,
        name: '::Menu:Notifications',
        order: 4,
        layout: eLayoutType.application,
        requiredPolicy: 'Ldm.Notifications',
      },
    ]);
  };
}
