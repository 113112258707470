<abp-page
  [title]="'AbpIdentity::OrganizationUnits' | abpLocalization"
  [toolbar]="organizationUnits"
>
  <div class="row">
    <div *abpPermission="'AbpIdentity.OrganizationUnits.ManageOU'" class="col-12 col-lg-6 col-xl-5">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h5>
            {{ 'AbpIdentity::OrganizationTree' | abpLocalization }}
          </h5>
          <div>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder="{{ 'AbpIdentity::Search' | abpLocalization }}"
              [(ngModel)]="searchTerm"
              (keydown.enter)="onSearchTermChange()"
            />
          </div>
        </div>
        <div class="card-body" [abpLoading]="loading">
          <abp-tree
            [nodes]="nodes"
            [(expandedKeys)]="expandedKeys"
            [(selectedNode)]="selectedUnit"
            [draggable]="true"
            (dropOver)="onDrop($event)"
          >
            <ng-template abpTreeNodeTemplate let-node>
              <i class="fas fa-folder fs-15px text-primary me-1"></i>
              {{ node.title }}
            </ng-template>

            <ng-template #menu let-node>
              <li class="pointer" ngbDropdownItem (click)="edit(node.origin.entity)">
                {{ 'AbpIdentity::Edit' | abpLocalization }}
              </li>
              <li class="pointer" ngbDropdownItem (click)="addSubUnit(node.origin.entity)">
                {{ 'AbpIdentity::AddSubUnit' | abpLocalization }}
              </li>
              <li class="pointer" ngbDropdownItem (click)="delete(node.origin.entity)">
                {{ 'AbpIdentity::Delete' | abpLocalization }}
              </li>
            </ng-template>
          </abp-tree>
          <p *ngIf="!loading && !nodes?.length" class="text-muted">
            {{ 'AbpIdentity::NoOrganizationUnits' | abpLocalization }}
          </p>
        </div>
      </div>
    </div>

    <div
      *abpPermission="
        'AbpIdentity.OrganizationUnits.ManageMembers || AbpIdentity.OrganizationUnits.ManageRoles'
      "
      class="col-12 col-lg-6 col-xl-7"
    >
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem *abpPermission="'AbpIdentity.OrganizationUnits.ManageMembers'">
          <a ngbNavLink>{{ 'AbpIdentity::Members' | abpLocalization }}</a>
          <ng-template ngbNavContent>
            <ng-container *ngIf="selectedUnit; else selectUnitMsg">
              <app-ldm-organization-members
                *abpReplaceableTemplate="{
                  inputs: {
                    selectedOrganizationUnit: { value: selectedUnit }
                  },
                  componentKey: organizationMembersKey
                }"
                [selectedOrganizationUnit]="selectedUnit"
              ></app-ldm-organization-members>
            </ng-container>
            <ng-template #selectUnitMsg>
              <p class="text-muted">
                {{ 'AbpIdentity::SelectAnOrganizationUnitToSeeMembers' | abpLocalization }}
              </p>
            </ng-template>
          </ng-template>
        </li>
        <!-- <li ngbNavItem *abpPermission="'AbpIdentity.OrganizationUnits.ManageRoles'">
            <a ngbNavLink>{{ 'AbpIdentity::Roles' | abpLocalization }}</a>
            <ng-template ngbNavContent>
              <ng-container *ngIf="selectedUnit; else selectUnitMsg">
                <abp-organization-roles
                  *abpReplaceableTemplate="{
                    inputs: {
                      selectedOrganizationUnit: { value: selectedUnit }
                    },
                    componentKey: organizationRolesKey
                  }"
                  [selectedOrganizationUnit]="selectedUnit"
                ></abp-organization-roles>
              </ng-container>
              <ng-template #selectUnitMsg>
                <p class="text-muted">
                  {{ 'AbpIdentity::SelectAnOrganizationUnitToSeeRoles' | abpLocalization }}
                </p>
              </ng-template>
            </ng-template>
          </li> -->
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</abp-page>
<abp-modal [(visible)]="isNodeModalVisible" [busy]="isModalBusy">
  <ng-template #abpHeader>
    <h3>
      {{
        (nodeForm.get('id').value
          ? 'AbpIdentity::EditOrganizationUnit'
          : 'AbpIdentity::NewOrganizationUnit'
        ) | abpLocalization
      }}
    </h3>
  </ng-template>

  <ng-template #abpBody>
    <div class="mb-3" *ngIf="nodeForm.get('parentId').value as parentId">
      <strong>{{
        'AbpIdentity::OrganizationUnit:Parent{0}' | abpLocalization : getParentName(parentId)
      }}</strong>
    </div>
    <form [formGroup]="nodeForm" (ngSubmit)="save()" validateOnSubmit>
      <abp-extensible-form [selectedRecord]="selectedUnit"></abp-extensible-form>

      <div class="mb-3 form-group" formGroupName="extraProperties">
        <label for="type">
          {{ '::OrganizationUnitType' | abpLocalization }} *
        </label>
        <div class="mb-3 mt-2">
          <mat-radio-group formControlName="OrganizationUnitType" class="mat-mdc-radio-group">
            <mat-radio-button *ngFor="let option of organizationUnitTypeOptions;" [value]="option.value" class="mat-mdc-radio-button mat-accent">
              {{ '::Enum:OrganizationUnitTypeEnum:' + option.value | abpLocalization }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpIdentity::Cancel' | abpLocalization }}
    </button>
    <abp-button iconClass="fa fa-check" (click)="save()" [disabled]="nodeForm?.invalid">{{
      'AbpIdentity::Save' | abpLocalization
    }}</abp-button>
  </ng-template>
</abp-modal>
